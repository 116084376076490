import * as React from 'react'
import { useRouter } from 'next/router'

import { getAuthToken } from '@/service/auth/store'

const cache = {
  isLoad: false,
}

type Props = {
  children: JSX.Element | JSX.Element[]
}

const CheckRedirectAboutPage = ({ children }: Props) => {
  const { pathname, replace } = useRouter()

  React.useEffect(() => {
    const isLoad = cache.isLoad
    cache.isLoad = true
    // 브라우저 접속 최초 한번 '/' path 체크해서 비로그인시 랜딩페이지로 이동
    if (!isLoad && pathname === '/') {
      if (!getAuthToken()) {
        replace('/about')
      }
    }
  }, [pathname, replace])

  return <>{children}</>
}

export default CheckRedirectAboutPage
